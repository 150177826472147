// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

//* -------------------------------------------------------------------------- */
// Zap

@mixin zap($img) {
    position: relative;

    &:after {
        background-image: url('#{$img}');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        content: '';
        display: block;
        height: 0.3em;
        left: -0.2em;
        position: absolute;
        top: calc(100% - 0.15em);
        width: calc(100% + 0.4em);
    }
}
