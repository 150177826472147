// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

// Sidebar menu

.mzp-c-sidemenu {
    @include text-body-md;
    background-color: $color-marketing-gray-20;
    padding: $spacing-sm $spacing-lg;
    margin: 0 -#{$spacing-lg} $layout-sm;
}

.mzp-c-sidemenu-summary {
    display: none;

    ul {
        margin: 0;
    }

    li {
        @include bidi((
            (margin-right, $spacing-xs, margin-left, 0),
        ));
        color: $color-marketing-gray-80;
        display: inline-block;

        &:after {
            @include bidi((
                (margin-left, $spacing-xs, margin-right, 0),
                (transform, none, translateY(3px) rotate(180deg)),
            ));
            content: '\25B8'; // right pointing triangle
            display: inline-block;
        }

        &:last-child:after {
            content: '';
            margin: 0;
        }

        &.mzp-is-current {
            color: $color-black;
        }
    }
}

.mzp-c-sidemenu-main {
    ul {
        margin: 0;
    }

    li {
        margin-bottom: .25em;
    }

    a {
        border-radius: $border-radius-xs;
        display: block;
        padding: .35em $spacing-lg;
        text-decoration: none;
        transition: background-color 100ms ease-in;

        &:hover,
        &:focus,
        &:active {
            background-color: rgba(0, 0, 0, .05);
            text-decoration: underline;
        }
    }

    .mzp-is-current a {
        font-weight: bold;
        background-color: $color-marketing-gray-20;
    }
}

.mzp-c-sidemenu-label {
    @include font-base;
    @include text-body-sm;
    @include bidi((
        (padding-right, $spacing-lg, padding-left, 0),
    ));
    position: relative;

    &:after {
        @include bidi((
            (right, 0, left, auto),
        ));
        @include transform(rotate(90deg));
        color: $color-marketing-gray-80;
        content: none;
        font-size: 1.5em;
        position: absolute;
        top: 0;
    }
}

.mzp-c-sidemenu-title {
    @include font-base;
    @include text-body-lg;
    line-height: 1.5;
    margin-bottom: .25em;

    a {
        padding: .35em $spacing-md;
    }
}

.js {
    .mzp-c-sidemenu-summary {
        display: block;
        padding: $spacing-sm $spacing-lg;
        margin: -#{$spacing-sm} -#{$spacing-lg};
    }

    .mzp-c-sidemenu-main {
        display: none;
    }

    .mzp-c-sidemenu-label:after {
        content: '\25B8'; // right pointing triangle
    }

    .mzp-is-active {
        .mzp-c-sidemenu-label:after {
            transform: rotate(-90deg);
        }

        .mzp-c-sidemenu-main {
            @include text-body-md;
            display: block;
            margin: $spacing-md -#{$spacing-md} 0;
        }
    }
}

@media #{$mq-md} {
    .js {
        .mzp-c-sidemenu-summary {
            display: none;
        }

        .mzp-c-sidemenu-main {
            display: block;
        }

        .mzp-is-active .mzp-c-sidemenu-main {
            margin: 0;
        }

        .mzp-c-sidemenu {
            @include text-body-md;
            background-color: transparent;
            padding: 0;
            margin: 0;
        }
    }
}
