// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.


@import '../includes/lib';

[class*="mzp-has-zap"] strong {
    @include zap('#{$image-path}/zaps/zap-01.svg');
}

@for $num from 2 through 18 {
    .mzp-has-zap-#{$num} strong {
        // add leading zero if necessary
        $zero: '';
        @if $num < 10 {
            $zero: 0;
        }

        &::after {
            background-image: url('#{$image-path}/zaps/zap-#{$zero}#{$num}.svg');
        }
    }
}
