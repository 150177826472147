// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import 'logo';


// can't re-use wordmark mixin because VPN uses the "stack" version by default
@each $layout-size, $logo-size in $logo-sizes {
    $path : '#{$image-path}/logos/mozilla/vpn/logo-word-hor-stack.svg';
    $path_white : '#{$image-path}/logos/mozilla/vpn/logo-word-hor-stack-white.svg';

    .mzp-c-wordmark.mzp-t-product-vpn.mzp-t-wordmark-#{$layout-size} {
        background-image: url('#{$path}');

        .mzp-t-dark & {
            background-image: url('#{$path_white}');
        }
    }

}
