// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

//* -------------------------------------------------------------------------- */
// Base emphasis Box class
.mzp-c-emphasis-box {
    background-color: get-theme('background-color');
    border-radius: $border-radius-md;
    box-shadow: $box-shadow-sm;
    margin: $spacing-md;
    padding: $spacing-xl;

    &.mzp-t-dark {
        background-color: get-theme('background-color-inverse');
        color: get-theme('body-text-color-inverse');
    }
}
