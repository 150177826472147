// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';


@media #{$mq-md} {
    .mzp-l-columns {
        display: grid;
        grid-gap: get-theme('v-grid-sm') get-theme('h-grid-md');
        gap: get-theme('v-grid-sm') get-theme('h-grid-md');

        &.mzp-t-columns-two,
        &.mzp-t-columns-four {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    // Remove standard bottom margin from multi-column lists
    ul.mzp-l-columns,
    ol.mzp-l-columns {
        margin-bottom: 0;
    }
}

@media #{$mq-lg} {
    .mzp-l-columns {
        grid-gap: get-theme('v-grid-lg') get-theme('h-grid-xl');
        gap: get-theme('v-grid-lg') get-theme('h-grid-xl');

        &.mzp-t-columns-three {
            grid-template-columns: repeat(3, 1fr);
        }

        &.mzp-t-columns-four {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

// Only one column in small containers
.mzp-t-content-sm .mzp-l-columns,
.mzp-t-content-sm.mzp-l-columns {
    display: block;
}

// Change three columns to one in medium containers
.mzp-t-content-md .mzp-l-columns.mzp-t-columns-three,
.mzp-t-content-md.mzp-l-columns.mzp-t-columns-three {
    display: block;
}

// Smaller gutters in medium containers
.mzp-t-content-md .mzp-l-columns,
.mzp-t-content-md.mzp-l-columns{
    grid-column-gap: get-theme('h-grid-md');
    column-gap: get-theme('h-grid-md');
}

// Change four columns to two in medium containers, plus smaller gutters
.mzp-t-content-md .mzp-l-columns.mzp-t-columns-four,
.mzp-t-content-md.mzp-l-columns.mzp-t-columns-four {
    grid-template-columns: repeat(2, 1fr);
}

// Smaller gutters on three and four columns in large containers
.mzp-t-content-lg .mzp-l-columns.mzp-t-columns-three,
.mzp-t-content-lg.mzp-l-columns.mzp-t-columns-three,
.mzp-t-content-lg .mzp-l-columns.mzp-t-columns-four,
.mzp-t-content-lg.mzp-l-columns.mzp-t-columns-four {
    grid-column-gap: get-theme('h-grid-md');
    column-gap:get-theme('h-grid-md');
}
