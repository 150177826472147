// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

//* -------------------------------------------------------------------------- */
// A small content block with a small image, usually an icon or spot illustration.

.mzp-c-picto {
    @include border-box;
    margin: 0 auto $layout-md;
    max-width: $content-md;
}

.mzp-c-picto-heading {
    @include text-title-xs;
}

.mzp-c-picto-image {
    margin-bottom: $layout-xs;
}


// Center aligned
.mzp-t-picto-center {
    .mzp-c-picto {
        text-align: center;
    }

    .mzp-c-picto-image {
        margin: 0 auto $layout-xs;
    }
}


// Image on the side
@media #{$mq-sm} {
    .mzp-t-picto-side {
        .mzp-c-picto {
            @include bidi((
                (padding-left, $layout-xl, 0),
                (padding-right, 0, $layout-xl),
            ));
            position: relative;
        }

        .mzp-c-picto-image {
            @include bidi((
                (left, 0, auto),
                (right, auto, 0),
            ));
            display: block;
            margin: 0 auto;
            position: absolute;
            text-align: center;
            width: $layout-lg;
        }
    }
}


// No bottom margins and full width in a multi-column layout
@media #{$mq-md} {
    .mzp-l-columns .mzp-c-picto {
        margin-bottom: 0;
        width: 100%;
    }
}


// Change side picto to top when in four columns and a narrow container
.mzp-t-content-lg .mzp-l-columns.mzp-t-columns-four.mzp-t-picto-side,
.mzp-t-content-lg .mzp-l-columns.mzp-t-columns-four .mzp-t-picto-side,
.mzp-t-content-lg.mzp-l-columns.mzp-t-columns-four.mzp-t-picto-side,
.mzp-t-content-lg.mzp-l-columns.mzp-t-columns-four .mzp-t-picto-side {
    .mzp-c-picto {
        padding: 0;
    }

    .mzp-c-picto-image {
        margin-bottom: $layout-xs;
        position: static;
        text-align: inherit;
        width: auto;
    }
}


// Change side picto to top when in four columns and a narrow viewport
@media #{$mq-lg} {
    .mzp-t-columns-four.mzp-t-picto-side,
    .mzp-t-columns-four .mzp-t-picto-side {
        .mzp-c-picto {
            padding: 0;
        }

        .mzp-c-picto-image {
            margin-bottom: $layout-xs;
            position: static;
            text-align: inherit;
            width: auto;
        }
    }
}
