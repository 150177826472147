// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

.mzp-c-menu-list {
    display: inline-block;
}

.mzp-c-menu-list-title {
    font-family: get-theme('button-font-family');
    font-size: inherit;
    font-weight: bold;
}

.mzp-c-menu-list-list {
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .3);
    border-radius: $border-radius-sm;
}

.mzp-c-menu-list-item {
    display: inline-block;

    a {
        color: $color-link;
        display: inline-block;
        padding: $spacing-sm;
        position: relative;
        text-decoration: none;

        .mzp-t-download & {
            @include bidi(((padding-right, $spacing-sm * 2 + 14px, padding-left, $spacing-sm),));

            &::after {
                @include background-size(20px, 20px);
                @include bidi(((right, $spacing-sm, left, auto),));
                bottom: $spacing-sm;
                content: '';
                display: block;
                position: absolute;
                top: $spacing-sm;
                width: 14px;
            }
        }

        &:hover,
        &:focus {
            background: $color-marketing-gray-20;
            color: $color-link-hover;

            &::after {
                background: $url-image-download-link-hover center center no-repeat;
            }
        }
    }
}


//* ---- */
// JS init

//TODO: hide menu if JS enabled

.is-details {
    &.mzp-c-menu-list {
        position: relative;
    }

    .mzp-c-menu-list-title {
        button {
            @include bidi(((padding, 0 (16px + $spacing-sm) 0 0, 0 0 0 (16px + $spacing-sm)),));
            background: transparent;
            border: 0;
            color: $color-link;
            cursor: pointer;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            position: relative;
            text-align: inherit;
            text-decoration: underline;
            width: 100%;

            &:after {
                @include background-size(20px, 20px);
                @include bidi(((right, 0, left, auto),));
                background: $url-image-arrow-down-link center bottom no-repeat;
                bottom: 1px;
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                width: 16px;
                @include transition(transform 200ms ease-in-out);
            }

            &[aria-expanded=true]:after {
                @include transform(scaleY(-1));
            }

            &:hover,
            &:focus {
                color: $color-link-hover;
                text-decoration: none;

                &:after {
                    background-image: $url-image-arrow-down-link-hover;
                }
            }

        }
    }

    .mzp-c-menu-list-list {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1;
        padding: $spacing-xs 0;
        min-width: 150px;

        &.is-closed {
            display: none;
        }
    }

    .mzp-c-menu-list-item {
        display: block;

        a {
            display: block;
        }
    }
}
