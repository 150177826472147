// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

.mzp-c-article {
    width: $content-md;
    max-width: 100%;

    @media #{$mq-md} {
        // Float left when sidebar is on the left
        .mzp-l-sidebar-left .mzp-l-main & {
            @include bidi(((float, left, right),));
        }

        // Float right when sidebar is on the right
        .mzp-l-sidebar-right .mzp-l-main & {
            @include bidi(((float, right, left),));
        }
    }
}

.mzp-c-article-title {
    @include text-title-lg;
}

.mzp-c-article-intro {
    @include text-body-lg;
}
