// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../../includes/lib';

input[list],
input[type='color'],
input[type='date'],
input[type='email'],
input[type='file'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
select,
textarea {

    .mzp-is-error & {
        border-color: $field-border-color-error;
        box-shadow: none;

        &:hover {
            border-color: $field-border-color-error-hover;
        }

        &:focus {
            border-color: $field-border-color-error;
            box-shadow: $field-focus-ring-error;
        }

        &:focus:hover {
            border-color: $field-border-color-error-hover;
        }
    }
}
