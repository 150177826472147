// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import 'logo';

$class: 'vpn';
$dir: 'mozilla/vpn';

@each $layout-size, $logo-size in $logo-sizes {
    @include logo($class, $dir, $layout-size, $logo-size);
}

.mzp-t-dark {
    @each $layout-size, $logo-size in $logo-sizes {

        .mzp-c-logo.mzp-t-product-#{$class}.mzp-t-#{$layout-size} {
            background-image: url('#{$image-path}/logos/mozilla/vpn/logo-flat-white.svg');
        }
    }
}

