// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

// --------------------------------------------------------------------------
// section heading

.mzp-c-section-heading {
    @include text-title-md;
    margin: 0 auto;
    padding: get-theme('h-grid-xs') $layout-xs;
    max-width: $content-lg;
    text-align: center;

    @media #{$mq-md} {
        padding: get-theme('h-grid-md') get-theme('v-grid-md');
    }

    @media #{$mq-xl} {
        padding: get-theme('h-grid-xl') get-theme('v-grid-xl');
    }

    // rely on l-content for padding if nested
    .mzp-l-content & {
        padding-left: 0;
        padding-right: 0;

        &:first-child {
            padding-top: 0;
        }
    }
}
