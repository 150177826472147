// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../includes/lib';

@warn "Picto Card component is now deprecated. Please migrate to use Picto: https://protocol.mozilla.org/patterns/molecules/picto.html";

//* -------------------------------------------------------------------------- */
// A small card with an icon and no link.

.mzp-c-card-picto {
    color: $color-black;
    padding: $spacing-xl 0;
    text-align: center;

    .mzp-c-card-picto-content {
        padding-top: 56px + $spacing-lg;
        position: relative;

        &:before {
            background: $color-marketing-gray-20;
            content: '';
            height: 56px;
            left: 50%;
            margin-left: -28px;
            position: absolute;
            top: 0;
            width: 56px;
        }
    }

    .mzp-c-card-picto-title {
        @include text-title-xs;
    }

    .mzp-c-card-picto-desc {
        @include text-body-md;
        color: get-theme('body-text-color-secondary');
        margin-bottom: 0;
    }

    // dark theme color styling
    .mzp-t-dark & {
        color: get-theme('body-text-color-inverse');

        .mzp-c-card-picto-desc {
            color: get-theme('body-text-color-secondary-inverse');
        }
    }

    @media #{$mq-md} {
        padding: $spacing-2xl 0;

        .mzp-c-card-picto-content {
            margin: 0 auto;
            max-width: 240px;
            padding: (56px + $spacing-lg) $spacing-lg 0;
        }
    }
}

.mzp-c-card-picto.mzp-c-card-picto-compact {
    @include bidi(((text-align, left, right),));

    .mzp-c-card-picto-title {
        @include text-title-sm;
    }

    .mzp-c-card-picto-content {
        @include bidi((
            (clear, left, right),
            (padding-left, 56px + $spacing-lg, padding-right, 0),
        ));
        padding-top: 0;
        position: relative;

        &:before {
            @include bidi((
                (left, 0, auto),
                (right, auto, 0),
            ));
            background: $color-light-gray-30;
            content: '';
            height: 56px;
            margin: 0;
            position: absolute;
            top: 0;
            width: 56px;
        }
    }

    @media #{$mq-md} {
        .mzp-c-card-picto-content {
            max-width: 320px;
        }
    }
}
