// This Source Code Form is subject to the terms of the Mozilla Public
// License, v. 2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/.

@import '../../includes/lib';


$logo-sizes: (
    xs: 'xs',
    sm: 'xs',
    md: 'xs',
    lg: 'sm',
    xl: 'md',
    2xl: 'lg',
);

.mzp-c-logo {
    @include bidi(((background-position, top left, top right),));
    @include image-replaced;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin-bottom: $layout-sm;
    margin-top: 0;

    &.mzp-t-logo-xs {
        height: $layout-xs;
        margin-bottom: $layout-xs;
        width: $layout-xs;
    }

    &.mzp-t-logo-sm {
        height: $layout-sm;
        width: $layout-sm;
    }

    &.mzp-t-logo-md {
        height: $layout-md;
        width: $layout-md;
    }

    &.mzp-t-logo-lg {
        height: $layout-lg;
        margin-bottom: $layout-md;
        width: $layout-lg;
    }

    &.mzp-t-logo-xl {
        height: $layout-xl;
        margin-bottom: $layout-lg;
        width: $layout-xl;
    }
}

@mixin logo($product, $dir, $layout-size, $logo-size) {
    $path : '#{$image-path}/logos/#{$dir}/logo.svg';

    .mzp-c-logo.mzp-t-product-#{$product}.mzp-t-logo-#{$layout-size} {
        background-image: url('#{$path}');
    }
}
